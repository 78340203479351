import DOMPurify from "dompurify";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { INTERNAL } from "../../../IIPconstants";


const SpotLightPage = (props) => {
  const { oktaUser } = props;
  const location = useLocation();
  const link = Object.keys(oktaUser).length === 0 ? "/" : INTERNAL;
  const spotLightData = location.state.spotLightData.data;
  const isBoli = useLocation().pathname.includes("bolispotlight") ? "BOLI" : "COLI";
  return (
    <>
      <div className="container">
        <div className="row">
          <Link to={link + "/"} data-testid="iip-backToDash" className="btn btn-link">
            <span className="icon-arrow-circle-spin-left" />
            <span>Back To Dashboard</span>
          </Link>
        </div>
        <h2 data-testid="iip-mainHeader"> {spotLightData.mainHeader + " " + isBoli} </h2>
        <h3 data-testid="iip-date"> {spotLightData.date} </h3>
        {spotLightData.listOfSections.length !== 0 && spotLightData.listOfSections.map((currentObj, index) => {
          return (
            <div data-testid={`spotlight-announcement-${index+1}`} key={index}>
              <h4 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(currentObj.header, { ADD_ATTR: ["target"] }) }} />
              <p> {currentObj.description} </p> 
            </div>
          );
        })}
        
        <div className="container row" data-testid="spotlight-moneyrate">
          <h4> {spotLightData.dataHeader && spotLightData.dataHeader} </h4>
          {spotLightData.dataSections.length !== 0 && spotLightData.dataSections.map((currentObj, index) => {
            return (
              <div className="container" key={index}>
                {currentObj.mainHeader ? <h3 className="table-headers-dates"> {currentObj.mainHeader} </h3> : <h4 className="sub-heading-spotlight"> {currentObj.subheader}
                  <span style={{ display: "block" }}> <h6 className="article sub-heading-spotlight">{currentObj.description}</h6></span>
                </h4>
                }
                <div className="container mm-spacing--minor">
                  <div className="row mm-spacing--minor">
                    {currentObj.dataTiles.map((obj, index) => {
                      const numOfHeaders = 12/currentObj.columnWidth;
                      const firstColStyle = numOfHeaders > 2 ? index % numOfHeaders === 0 ? "indent-column" : "" : "";
                      const isHeader = index < numOfHeaders;
                      const headerComp = <h4 className="table-headers-dates" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(obj.tileDescription, { ADD_ATTR: ["target"] }) }} /> 
                      const tableElements = <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(obj.tileDescription, { ADD_ATTR: ["target"] }) }} />
                      return (
                        <>
                          { currentObj.mainHeader ? endOfTheYearTable(currentObj, index, isHeader, headerComp, tableElements, firstColStyle) : generalAccountsView(index, obj) }
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={`${spotLightData.dataSections.length === 0 ? "mm-spacing" : "container mm-spacing"}`} data-testid="spotlight-footer">
          {spotLightData.disclaimers.length !== 0 && spotLightData.disclaimers.map((currentObj, index) => {
            return (
              <div className={`${spotLightData.dataSections.length === 0 ? "" : "container"} ${currentObj.isLink ? "mm-spacing--minor" : ""}`} key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(currentObj.disclaimerText, { ADD_ATTR: ["target"] }) }} />
            );
          })}
        </div>

      </div>
    </>
    
  );
};

const generalAccountsView = (index, obj) => {
  return (
    <div className="col-4 mm-spacing--minor" key={index}>
      <div key={index} className={obj.urlLink === "" ? "text-border" : "text-border-links"}>
        {
          obj.urlLink === "" ? 
            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(obj.tileDescription, { ADD_ATTR: ["target"] }) }} /> :
            <a href={obj.urlLink} rel="noreferrer" target="_blank"> {obj.tileDescription} </a>
        }
      </div> 
    </div>
  )
}

const endOfTheYearTable = (currentObj, index, isHeader, headerComp, tableElements, firstColStyle) => {
  return (
    <div className={`col-${currentObj.columnWidth} table-border`} key={index}>
      <div className={`table-headers-dates ${firstColStyle}`}> {isHeader ? headerComp :  tableElements}  </div>
    </div>
  )
}


export default SpotLightPage;
