import DOMPurify from "dompurify";
import React from "react";
import { Link } from "react-router-dom";

const SpotLightHeader = (props) => {
  const { content, path, type } = props;
  const orderedSpotLights = content.dashboard.spotlightList.sort((a,b) => new Date(b.orderDate) - new Date(a.orderDate));
  return (
    <div className="row">
      <div className="col-sm-1">
        <picture data-testid="iip-lighhouse">
          <span role="img" title="light house">
            <img alt="lighthouse" src="./lighthouse.svg"></img>
          </span>
        </picture>
      </div>
      <div className="col-sm-11">
        <b data-testid="iip-heading">
          Spotlight on {type}
        </b>
        {orderedSpotLights.map((currentObj, index) => {
          const directedSpotLightUrl = type === "BOLI" ? "/bolispotlight" : "/colispotlight";
          if (currentObj.spotlightType.includes(type)) {
            return (
              <div className="mm-spacing--minor" key={index}>
                <Link 
                  to={`${path}${directedSpotLightUrl}${currentObj.spotlightLink}`} 
                  state = {{ spotLightData: orderedSpotLights[index] }}
                  data-testid={currentObj.spotlightTestData}>
                  <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(currentObj.spotlightText, { ADD_ATTR: ["target"] }) }} />
                </Link>
              </div>);
          }
        })
        }
      </div>
    </div>
  );
};

export default SpotLightHeader;
