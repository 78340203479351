import axios from "axios";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { INTERNAL } from "../../IIPconstants";
import tealiumPageViewEvent from "../../tealiumPageViewEvent";
import Checkboxes from "../Checkboxes/Checkboxes";
import CommonExternalLink from "../CommonExternalLink/CommonExternalLink";
import Contact from "../Contact/Contact";
import Disclaimer from "../Disclaimer/Disclaimer";
import FundCompany from "../FundCompany/FundCompany";
import IIPCollapse from "../IIPCollapse/IIPCollapse";
import RegisteredInvestment from "../RegisteredInvestment/RegisteredInvestment";
import Search from "../Search/Search";
import TopContent from "../TopContent/TopContent";

const ProductPage = (props) => {
  const [content, setContent] = useState({});
  const [fundsInformation, setFundsInformation] = useState([]);
  const [filterFundsInformation, setFilterFundsInformation] = useState([]);
  const [err, setErr] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [checkboxFilter, setCheckboxFilter] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const { oktaUser, auth0User, location, path } = props;
  const link = Object.keys(oktaUser).length === 0 ? "/" : INTERNAL;
  const showSl20Product = location.pathname.includes("sl20");
  const showSL21Product = location.pathname.includes("sl21");
  const showSL14Product = location.pathname.includes("sl14");
  const tealiumProductType = location.pathname.split("/").pop().toUpperCase();
  let urlType = "";
  let commonExternalLinkList = [];
  let disclaimer = "";

  useEffect(() => {
    let getContent = axios.get("/institutional-insurance/getContent");
    let getFundsInformation = axios.get(
      "/institutional-insurance/getFundsInformation"
    );
    axios
      .all([getContent, getFundsInformation])
      .then(
        axios.spread((...responses) => {
          const content = responses[0];
          const fundsInfo = responses[1];
          setContent(content.data);
          setFundsInformation(fundsInfo.data);
          setFilterFundsInformation(fundsInfo.data);
          setSpinner(false);
          tealiumPageViewEvent(
            `Insurance Portal ${tealiumProductType} Product`,
            "Content",
            `${tealiumProductType} Funds Information`
          );
        })
      )
      .catch((error) => {
        setErr(error);
        setSpinner(false);
      });
  }, [err, tealiumProductType]);

  useEffect(() => {
    let filteredFunds = [...fundsInformation];

    filteredFunds = filteredFunds.map((fund) => {
      // Create an empty fund name object
      let fundNameObj = {
        fundCompany: "",
        funds: [],
      };

      // check if checkboxes are selected
      if (checkboxFilter.length > 0) {
        // run the code if search text is empty
        if (searchFilter === "") {
          checkboxFilter.forEach((category) => {
            fund.funds.forEach((individualFund) => {
              if (
                individualFund.fundType === category &&
                fundNameObj.funds.indexOf(individualFund) < 0
              ) {
                fundNameObj.funds.push(individualFund);
              }
            });
          });
        } else {
          // run the code if search text has a value and checkbox is also selected
          checkboxFilter.forEach((category) => {
            fund.funds.forEach((individualFund) => {
              if (
                individualFund.fundType === category &&
                fundNameObj.funds.indexOf(individualFund) < 0 &&
                searchFilter !== "" &&
                individualFund.fundShareClass
                  .toLowerCase()
                  .includes(searchFilter.toLowerCase())
              ) {
                fundNameObj.funds.push(individualFund);
              }
            });
          });
        }
      } else {
        // run the code when search has text but no checkbox selected
        fund.funds.forEach((individualFund) => {
          if (
            searchFilter !== "" &&
            individualFund.fundShareClass
              .toLowerCase()
              .includes(searchFilter.toLowerCase())
          ) {
            fundNameObj.funds.push(individualFund);
          }
        });
      }

      // check if fund name has a match
      if (fundNameObj.funds.length > 0) {
        fundNameObj.fundCompany = fund.fundCompany;
      }

      // return full list is search text is empty and no checkbox selected
      if (searchFilter === "" && checkboxFilter.length < 1) {
        return fund;
      }

      // check if fund company has a match
      // return fund company as the priority
      if (
        searchFilter !== "" &&
        checkboxFilter.length < 1 &&
        fund.fundCompany.toLowerCase().includes(searchFilter.toLowerCase())
      ) {
        return fund;
      }

      // return fund name if there is no fund company match
      if (fundNameObj.fundCompany) {
        return fundNameObj;
      }

      // return empty object if there is no match
      return null;
    });

    // do a filter on the result to remove any null values
    filteredFunds = filteredFunds.filter((fund) => {
      return fund !== null;
    });

    setFilterFundsInformation(filteredFunds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter, checkboxFilter]);

  // Set Fund Categories based on the funds information recevied from the server
  const fundCategories = [];
  fundsInformation.forEach((fundsInfo) => {
    const funds = fundsInfo.funds;
    funds.forEach((fund) => {
      if (!fundCategories.includes(fund.fundType)) {
        fundCategories.push(fund.fundType);
      }
    });
  });
  fundCategories.sort();

  if (spinner) {
    return (
      <div className="mm-page-is-loading" data-testid="iip-spinner">
        <div
          className="mm-dots-loading-xl"
          role="img"
          aria-label="MassMutual Logo"
        />
        <span className="sr-only" aria-busy="true" aria-live="assertive">
          Page is loading
        </span>
      </div>
    );
  }
  if (err) {
    return (
      <div className="container">
        <div
          className="alert alert-danger"
          role="alert"
          data-testid="iip-alert-error"
        >
          Server Error. Please try again.
        </div>
      </div>
    );
  }
  if (showSL21Product) {
    urlType = "/sl21";
    commonExternalLinkList = content.productPage.sl21formList;
    disclaimer = content.disclaimerSl21;
  } else if (showSl20Product) {
    urlType = "/sl20";
    commonExternalLinkList = content.productPage.sl20formList;
    disclaimer = content.disclaimerSl20;
  } else {
    urlType = "/sl14";
    commonExternalLinkList = content.productPage.sl14formList;
    disclaimer = content.disclaimerSl14;
  }
  const prefixUrl = `/institutional-insurance${path}/product${urlType}/`;
  return (
    <>
      <div className="container">
        <div className="row">
          <Link to={ link + "/" } className="btn btn-link" data-testid="mg-back">
            <span className="icon-arrow-circle-spin-left" />
            <span>Back To Dashboard</span>
          </Link>
        </div>
        <h2 className="h2" data-testid="iip-heading">
          {showSl20Product && content.topContent.titleSl20}
          {showSL21Product && content.topContent.titleSl21}
          {showSL14Product && parse(content.topContent.titleSl14)}
        </h2>
        <div className="row col-sm-8" data-testid="iip-top-content">
          <TopContent
            content={content.topContent}
            showSlProduct={location.pathname}
          />
        </div>
        {showSL14Product && (
          <div className="row col-sm" data-testid="iip-sl14notice">
            {parse(content.topContent.sl14notice)}
          </div>
        )}
        <div className="row">
          <div className="col-sm-8" data-testid="iip-form">
            <CommonExternalLink
              heading={
                showSL14Product ? "Performance & Informational Data" : "Forms"
              }
              content={commonExternalLinkList}
              prefixUrl={prefixUrl}
            />
          </div>
          <div className="col-md-4 col-sm-4" data-testid="iip-contact">
            <Contact content={content.contact} />
          </div>
        </div>
        {!showSl20Product && !showSL14Product && (
          <div data-testid="iip-fundperformance">
            <CommonExternalLink
              heading="Fund Performance"
              showSL14Product={showSL14Product}
              content={content.productPage.fundPerformance}
              prefixUrl={prefixUrl}
            />
          </div>
        )}
        {!showSl20Product && (
          <IIPCollapse
            content={
              showSL14Product
                ? content.productPage.alternativeDivisionSL14
                : content.productPage.alternativeDivision
            }
            boolcollapse={true}
            path={path}
            prefixUrl={prefixUrl}
            id={"alternateDivision"}
            fontSize={"h4"}
            spacing="mm-spacing"
          />
        )}
        <div className="row mm-spacing">
          <RegisteredInvestment
            showSLActive={[showSL14Product, showSL21Product]}
            content={content.productPage}
          />
          {!showSL14Product && (
            <div className="col-md-3 col-sm-3" data-testid="iip-categories">
              <Checkboxes
                checkboxFilter={setCheckboxFilter}
                categories={fundCategories}
              />
            </div>
          )}
          <div className="col-md-9 col-sm-9">
            {!showSL14Product && (
              <div
                className="col-md-9 col-sm-9 row mm-spacing"
                data-testid="iip-search-input"
              >
                <Search searchFilter={setSearchFilter} />
              </div>
            )}
            <div data-testid="iip-fund-details">
              <FundCompany
                funds={filterFundsInformation}
                oktaUser={oktaUser}
                auth0User={auth0User}
              />
            </div>
          </div>
        </div>
      </div>
      <Disclaimer content={disclaimer} />
    </>
  );
};

export default ProductPage;
